"use client";

import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { motion } from "framer-motion";
import { cleanContactForm } from "@book-the-play/utils/helperFunctions";
import { validateContactForm } from "@book-the-play/utils/validate";
import { FormInputField } from "./FormInputField";
import { Typography } from "@libs/src/components/Typography";
import { cn } from "@libs/utils/twMerge";
import { useSendMessage } from "./hook/useSendMessage";
import "./styles/partnerForm.scss";

let timestamp: NodeJS.Timeout;

const initialForm = {
  name: "",
  email: "",
  mobile: "",
  message: "",
};

const initialErrs = {
  name: "",
  email: "",
  mobile: "",
  message: "",
};

export const PartnerForm = () => {
  const [formData, setFormData] = useState(initialForm);
  const [fieldError, setFieldError] = useState(initialErrs);
  const [isSend, setIsSend] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    if (isSend) {
      timestamp = setTimeout(() => {
        setIsSend(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timestamp);
    };
  }, [isSend]);

  const changeHandler = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setFieldError(initialErrs);
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const { postData: mutate } = useSendMessage();

  const handleSendMessage = useCallback(async () => {
    const data = cleanContactForm(formData);
    const { isError, errorFound } = validateContactForm(data);

    if (isError) {
      setFieldError(errorFound);

      return;
    }

    try {
      const res = await mutate(data);
      // Throw error with status code in case Fetch API req failed
      if (!res.ok) {
        throw new Error(res.status.toString());
      } else {
        setIsSend(true);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("error saving contact details");
    }
  }, [formData, mutate]);

  const handleSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!executeRecaptcha) {
      return;
    }
    executeRecaptcha("contactFormAdvanced").then(() => {
      handleSendMessage();
    });
  };

  const { name, email, mobile, message } = formData ?? {};

  return (
    <form
      id="contactFormAdvanced-1"
      onSubmit={handleSubmitForm}
      noValidate
      className="wb-flex wb-flex-col wb-gap-3"
    >
      <div className="wb-flex wb-flex-wrap mlg:wb-flex-col">
        <div className="wb-w-[50%] wb-flex-initial wb-pr-4 mlg:wb-w-full mlg:wb-pr-0">
          <FormInputField
            label="Full Name"
            type="text"
            name="name"
            id="name"
            value={name}
            error={fieldError.name}
            handleChange={changeHandler}
          />
        </div>
        <div className="wb-w-[50%] mlg:wb-w-full">
          <FormInputField
            label="Email Address"
            type="email"
            name="email"
            id="email"
            value={email}
            error={fieldError.email}
            handleChange={changeHandler}
          />
        </div>
      </div>
      <div className="wb-flex wb-flex-wrap">
        <div className="wb-w-full">
          <FormInputField
            label="Mobile"
            type="number"
            name="mobile"
            id="mobile"
            value={mobile}
            error={fieldError.mobile}
            handleChange={changeHandler}
          />
        </div>
      </div>
      <div className="wb-flex wb-flex-wrap">
        <div className="wb-mb-4 wb-w-full">
          <div className="wb-flex wb-flex-col wb-text-base wb-tracking-[0px] wb-font-normal">
            <Typography
              tag="span"
              className="wb-opacity-80 wb-text-dark-secondary wb-text-sm wb-mb-1"
            >
              Message
            </Typography>
            <textarea
              name="message"
              id="message"
              maxLength={5000}
              rows={6}
              className={cn(
                "wb-p-2 wb-mb-2 wb-bg-light wb-text-dark-secondary wb-leading-[20px] md:wb-leading-[27px] wb-border-solid wb-border-[1px] wb-py-2 wb-outline-none wb-text-sm wb-shadow-sm",
                {
                  "wb-border-slate-200": !fieldError.message,
                  "wb-border-[#ff334b]": fieldError.message,
                },
              )}
              value={message}
              onChange={changeHandler}
            />

            {Boolean(fieldError.message) && (
              <Typography
                tag="span"
                className="wb-text-[#ff334b] wb-text-xs sm:wb-text-sm wb-h-8 sm:wb-h-5 wb-mt-0 wb-mb-0"
              >
                {fieldError.message}
              </Typography>
            )}
          </div>
        </div>
      </div>
      {isSend && (
        <div className="wb-flex wb-flex-wrap">
          <div className="wb-mb-4">
            <Typography
              tag="span"
              className="success_msg border-style wb-text-base"
            >
              Success
            </Typography>
          </div>
        </div>
      )}
      <div className="wb-flex">
        <div className="wb-mb-5">
          <motion.div
            transition={{
              duration: 0.5,
            }}
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <button
              type="submit"
              id="partnerFormSubmit"
              defaultValue="Send Message"
              className="wb-bg-primary wb-text-light wb-py-5 wb-px-7 wb-text-base wb-font-bold wb-rounded-md hover:wb-bg-primary--300"
            >
              Send Request
            </button>
          </motion.div>
        </div>
      </div>
    </form>
  );
};
