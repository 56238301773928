"use client";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import appConfig from "@book-the-play/config/app.config";
import { PartnerForm } from "./PartnerForm";

const FormHOC = () => (
  <GoogleReCaptchaProvider
    reCaptchaKey={appConfig.recaptchaSiteKey}
    language="en"
    scriptProps={{
      async: false,
      defer: true,
      appendTo: "head",
      nonce: undefined,
    }}
    container={{
      parameters: {
        theme: "light",
      },
    }}
  >
    <PartnerForm />
  </GoogleReCaptchaProvider>
);

export default FormHOC;
