import { ChangeEvent } from "react";
import { Typography } from "@libs/src/components/Typography";
import { cn } from "@libs/utils/twMerge";

interface FormInputFieldProps {
  label: string;
  type: string;
  name: string;
  id: string;
  value: string;
  error: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const FormInputField = ({
  label,
  type,
  name,
  id,
  value,
  error,
  handleChange,
}: FormInputFieldProps) => {
  return (
    <div className="wb-flex wb-flex-col wb-text-base wb-tracking-[0px] wb-font-normal">
      <Typography
        tag="span"
        className="wb-opacity-80 wb-text-dark-secondary wb-text-sm wb-mb-1"
      >
        {label}
      </Typography>
      <input
        type={type}
        name={name}
        id={id}
        className={cn(
          "wb-outline-none wb-p-2 wb-mb-2 wb-bg-light wb-text-dark-secondary wb-leading-[20px] md:wb-leading-[27px] wb-border-solid wb-border-[1px] wb-py-2 wb-text-sm wb-shadow-sm",
          {
            "wb-border-slate-200": !error,
            "wb-border-[#ff334b]": error,
          },
        )}
        value={value}
        onChange={handleChange}
        autoComplete="off"
        maxLength={100}
      />
      {Boolean(error) && (
        <Typography
          tag="span"
          className="wb-text-[#ff334b] wb-text-xs sm:wb-text-sm wb-h-8 sm:wb-h-5 wb-mt-0 wb-mb-0"
        >
          {error}
        </Typography>
      )}
    </div>
  );
};
